import React, { useContext, useEffect, useState } from "react";
import styles from "./WhatsappLink.module.css";
import Container from "../../Components/Account/Container";
import whatsapp from "../../assets/Community/whatsappLarge.svg";
import RegularInput from "../../Components/TextComponents/RegularInput";
import { get } from "../../fetch";
import Context from "../../Context";

const WhatsappLink = () => {
  const [whatsappLink, setWhatsappLink] = useState(
    "https://chat.whatsapp.com/IwdPk92_d5qo8921dh2783hfDw2"
  );
  const { setLoading } = useContext(Context);

  useEffect(() => {
    setLoading(true);
    get("configs")
      .then((response) => {
        setWhatsappLink(response.whatsapp_group_url);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // alert("Looks like there's a problem. Reload the page and if this happens again, please come back later.");
      });
  }, []);

  const open = () => {
    window.open(whatsappLink, "_blank");
  };

  return (
    <Container>
      <div className={styles.container}>
        <img className={styles.img} src={whatsapp} alt="whatsapp" />
        <h2 className={styles.title}>WhatsApp Link</h2>
        <RegularInput
          value={whatsappLink}
          disabled
          type="url"
          placeholder="Enter whatsapp link..."
        />
        <button className={styles.button} onClick={open}>
          Open Lnk
        </button>
      </div>
    </Container>
  );
};

export default WhatsappLink;
