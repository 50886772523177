import React, { useContext, useEffect, useState } from "react";
import styles from "./Application.module.css";
import Container from "../../Components/Account/Container";
import cube from "../../assets/Account/cube.png";
import { useNavigate } from "react-router-dom";

import ReactPhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

import page1 from "../../assets/Dashboard/page1.svg";
import page2 from "../../assets/Dashboard/page2.svg";
import page3 from "../../assets/Dashboard/page3.svg";

import { post } from "../../fetch";
import Context from "../../Context";
import axios from "axios";
import { countriesArr } from "./countries";
import Select from "react-select";

const Application = () => {
  const searchStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      padding: 0,
      backgroundColor: "none",
      height: 45,
      marginTop: -6,
      width: "100%",
      display: "flex",
      alignItems: "center",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#437689" : null,
      color: state.isSelected ? "white" : null,
    }),
  };

  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const { setLoading } = useContext(Context);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    full_name: "",
    email: "",
    email_confirmation: "",
    phone: "",
    country: "Jordan",
    url: "",
    years_of_experience: "",
    business_outline: "",

    educational_background: "",
    professional_affiliations: "",
    strengths: "",
    reasons_to_join: "",

    referred_by: "",
  });

  useEffect(() => {
        const countryData = countriesArr.map((country) => country.name.common);
        setCountries(countryData.sort((a, b) => a.localeCompare(b)));
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const cleanURL = (url) => {
    let cleanedURL = url.replace(/(^\w+:|^)\/\//, "").replace("www.", "");
    cleanedURL = cleanedURL.replace(/\/+$/, "");
    return cleanedURL;
  };

  const back = () => {
    if (page === 1) {
      navigate("/dashboard/applications");
    } else {
      setPage(page - 1);
      scrollToTop();
    }
  };
  const next = (event) => {
    event.preventDefault();
    if (page === 1) {
      if (
        data.full_name !== "" &&
        data.email !== "" &&
        data.email_confirmation !== "" &&
        data.email_confirmation === data.email &&
        data.phone !== "" &&
        data.country !== "" &&
        data.years_of_experience !== "" &&
        data.business_outline.length >= 300
      ) {
        setPage(2);
        scrollToTop();
      } else {
        data.business_outline.length < 300 &&
          setErrors({
            ...errors,
            business_outline: "*This field must be no less than 300 characters",
          });
        data.years_of_experience.length < 1 &&
          setErrors({
            ...errors,
            years_of_experience: "*This field is required",
          });
        data.country.length < 1 &&
          setErrors({ ...errors, country: "*This field is required" });
        data.phone.length < 1 &&
          setErrors({ ...errors, phone: "*Please enter a valid phone number" });
        data.email_confirmation !== data.email &&
          setErrors({ ...errors, email_confirmation: "*Emails don't match" });
        data.email.length < 1 &&
          setErrors({ ...errors, email: "*Please enter a valid email" });
        data.full_name.length < 1 &&
          setErrors({ ...errors, full_name: "*This field is required" });
      }
    } else if (page === 2) {
      if (
        data.educational_background !== "" &&
        data.strengths.length >= 150 &&
        data.reasons_to_join.length >= 150
      ) {
        setPage(3);
        scrollToTop();
      } else {
        data.reasons_to_join.length < 150 &&
          setErrors({
            ...errors,
            reasons_to_join: "*This field must be no less than 150 characters",
          });
        data.strengths.length < 150 &&
          setErrors({
            ...errors,
            strengths: "*This field must be no less than 150 characters",
          });
        data.educational_background === "" &&
          setErrors({
            ...errors,
            educational_background: "*This field is required",
          });
      }
    } else {
      if (agree) {
        setLoading(true);
        post("apply", { ...data, url: cleanURL(data.url) })
          .then(() => {
            setLoading(false);
            alert(
              "Thank you for applying. Your application will be reviewd soon, you will recieve an email when you get accepted."
            );
            navigate("/");
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status === 404) {
              alert(
                "This referral code doesn't belong to any user. Make sure you've entered the correct referral code or leave the field empty."
              );
            } else {
              setPage(1);
              alert(
                `${
                  error.response.data.errors.email
                    ? error.response.data.errors.email + `\n`
                    : ""
                }${
                  error.response.data.errors.url
                    ? error.response.data.errors.url + `\n`
                    : ""
                }${
                  error.response.data.errors.phone
                    ? error.response.data.errors.phone
                    : ""
                }`
              );
            }
          });
      } else {
        alert(
          "Make sure you've filled all fields correctly and have agreed to the terms & conditions"
        );
      }
    }
  };

  return (
    <Container>
      <img className={styles.cube} src={cube} alt="cube" />
      <p className={styles.name}>Join the Community!</p>

      {page === 1 && <img className={styles.pages} src={page1} alt="" />}
      {page === 2 && <img className={styles.pages} src={page2} alt="" />}
      {page === 3 && <img className={styles.pages} src={page3} alt="" />}

      {page === 1 && (
        <>
          <p className={styles.section}>*Personal Information:</p>
          <div className={styles.inputContainer}>
            <label>Full name</label>
            <input
              value={data.full_name}
              onChange={(e) => setData({ ...data, full_name: e.target.value })}
              onInput={() => setErrors({ ...errors, full_name: "" })}
            />
            <span>{errors.full_name}</span>
          </div>
          <div className={styles.inputContainer}>
            <label>Email</label>
            <input
              type="email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              onInput={() => setErrors({ ...errors, email: "" })}
            />
            <span>{errors.email}</span>
          </div>
          <div className={styles.inputContainer}>
            <label>Confirm Email</label>
            <input
              type="email"
              value={data.email_confirmation}
              onChange={(e) => setData({ ...data, email_confirmation: e.target.value })}
              onInput={() => setErrors({ ...errors, email_confirmation: "" })}
            />
            <span>{errors.email_confirmation}</span>
          </div>
          <div className={styles.inputContainer}>
            <label>Phone</label>
            <ReactPhoneInput
              placeholder="+962 7 9999 9999"
              value={data.phone}
              onChange={(value) => setData({ ...data, phone: value })}
              defaultCountry="JO"
              onInput={() => setErrors({ ...errors, phone: "" })}
            />
            <span>{errors.phone}</span>
          </div>
          <div className={styles.inputContainer}>
            <label>Country</label>
            <Select
              className={styles.searchSelect}
              value={{
                label: data.country || "Jordan",
                value: data.country || "Jordan",
              }}
              onChange={(e) => {
                setData({ ...data, country: e.value });
                setErrors({ ...errors, country: "" });
              }}
              options={countries.map((option) => ({
                label: option,
                value: option,
              }))}
              styles={searchStyles}
              isSearchable
            />
            <span>{errors.country}</span>
          </div>
          <div className={styles.inputContainer}>
            <label>Website or Instagram (Optional)</label>
            <input
              value={data.url}
              onChange={(e) => setData({ ...data, url: e.target.value })}
              onInput={() => setErrors({ ...errors, url: "" })}
            />
          </div>

          <p className={styles.section}>*Entrepreneurial Background:</p>
          <div className={styles.inputContainer}>
            <label>Years of entrepreneurial experience</label>
            <input
              type="number"
              value={data.years_of_experience}
              onChange={(e) =>
                setData({ ...data, years_of_experience: e.target.value })
              }
              onInput={() => setErrors({ ...errors, years_of_experience: "" })}
            />
            <span>{errors.years_of_experience}</span>
          </div>
          <div className={styles.inputContainer}>
            <label>Tell us more about your business {"(at least 300 characters)"}</label>
            <textarea
              value={data.business_outline}
              onChange={(e) =>
                setData({ ...data, business_outline: e.target.value })
              }
              onInput={() =>
                data.business_outline.length >= 300 &&
                setErrors({ ...errors, business_outline: "" })
              }
            />
            <p className={styles.textAreaLength}>
              {data.business_outline.length}
            </p>
            <span>{errors.business_outline}</span>
          </div>
        </>
      )}

      {page === 2 && (
        <>
          <p className={styles.section}>*Qualifications and Achievements:</p>
          <div className={styles.inputContainer}>
            <label>Educational Background</label>
            <input
              value={data.educational_background}
              onChange={(e) =>
                setData({ ...data, educational_background: e.target.value })
              }
              onInput={() =>
                setErrors({ ...errors, educational_background: "" })
              }
            />
            <span>{errors.educational_background}</span>
          </div>
          <div className={styles.inputContainer}>
            <label>Professional Affiliations (Optional)</label>
            <input
              value={data.professional_affiliations}
              onChange={(e) =>
                setData({ ...data, professional_affiliations: e.target.value })
              }
            />
          </div>
          <div className={styles.inputContainer}>
            <label>
              Mention three strengths you have {"(at least 150 characters)"}
            </label>
            <textarea
              value={data.strengths}
              onChange={(e) => setData({ ...data, strengths: e.target.value })}
              onInput={() => setErrors({ ...errors, strengths: "" })}
            />
            <p className={styles.textAreaLength}>{data.strengths.length}</p>
            <span>{errors.strengths}</span>
          </div>
          <p className={styles.section}>*Commitment to the Community:</p>
          <div className={styles.inputContainer}>
            <label>Why do you want to join? {"(at least 150 characters)"}</label>
            <textarea
              value={data.reasons_to_join}
              onChange={(e) =>
                setData({ ...data, reasons_to_join: e.target.value })
              }
              onInput={() => setErrors({ ...errors, reasons_to_join: "" })}
            />
            <p className={styles.textAreaLength}>
              {data.reasons_to_join.length}
            </p>
            <span>{errors.reasons_to_join}</span>
          </div>
        </>
      )}

      {page === 3 && (
        <>
          <p className={styles.section}>Additional Information:</p>
          <div className={styles.inputContainer}>
            <label>Referral Code</label>
            <input
              value={data.referred_by}
              onChange={(e) =>
                setData({
                  ...data,
                  referred_by: e.target.value.replace(/\s/g, ""),
                })
              }
            />
            <span style={{ color: "black", textAlign: "left", marginTop: 5 }}>If you have a friend who's already a member, obtain free months for you and them by asking them for the referral code found in their personal profile.</span>
          </div>
          <div className={styles.tCContainer}>
            <input
              type="checkbox"
              className={styles.checkbox}
              checked={agree}
              onClick={() => setAgree(!agree)}
            />
            <p className={styles.terms}>
              I agree to the{" "}
              <button
                onClick={() => {
                  const newTab = window.open("/terms-and-conditions", "_blank");
                  newTab.focus();
                }}
              >
                Term & Conditions
              </button>
            </p>
          </div>
        </>
      )}

      {page !== 3 ? (
        <div className={styles.btnsContainer}>
          <button className={`${styles.btn} ${styles.back}`} onClick={back}>
            {page === 1 ? "Cancel" : "Back"}
          </button>
          <button className={`${styles.btn} ${styles.next}`} onClick={next}>
            Next
          </button>
        </div>
      ) : (
        <div className={styles.btnsContainer}>
          <button className={`${styles.btn} ${styles.back}`} onClick={back}>
            {page === 1 ? "Cancel" : "Back"}
          </button>
          <button className={`${styles.btn} ${styles.next}`} onClick={next}>
            {page === 3 ? "Apply" : "Next"}
          </button>
        </div>
      )}
    </Container>
  );
};

export default Application;

