import { useContext, useEffect, useState, CSSProperties } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Header from "./Components/Header";
import styles from "./App.module.css";
import Footer from "./Components/Footer";
import AccountHome from "./Pages/Account/AccountHome";
import EditProfile from "./Pages/Account/EditProfile";
import CommunityHome from "./Pages/Community/CommunityHome";
import CommunityMembers from "./Pages/Community/CommunityMembers";
import Member from "./Pages/Community/Member";
import CommunitySettings from "./Pages/Community/CommunitySettings";
import Packages from "./Pages/Community/Packages";
import Application from "./Pages/Auth/Application";
import LoginPage from "./Pages/Auth/LoginPage";
import ScrollToTop from "./Components/ScrollToTop";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Terms from "./Pages/Auth/Terms";
import PaymentMethod from "./Pages/Auth/PaymentMethod";
import Pay from "./Pages/Auth/Pay";

import Context from "./Context";
import WhatsappLink from "./Pages/Community/WhatsappLink";
import { PropagateLoader } from "react-spinners";
import ForgetPassword from "./Pages/Auth/ForgetPassword";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function App() {
  const { user, loading } = useContext(Context);
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const [addHeight, setAddHeight] = useState(true);

  useEffect(() => {
    if (
      pathname === "/account" ||
      pathname === "/change-password" ||
      pathname === "/edit-profile" ||
      pathname === "/community/whatsapp" ||
      (pathname.includes("member") && pathname !== "/community/special-members" && pathname !== "/community/members") ||
      pathname.includes("payment")
    ) {
      setAddHeight(false);
    } else {
      setAddHeight(true);
    }
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoad(true), 600);
  }, []);

  if (load)
    return (
      <>
        {loading && (
          <div className={styles.loaderContainer}>
            <PropagateLoader
              color="#437689"
              loading={true}
              cssOverride={override}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        <ScrollToTop />
        <Header />
        <div
          style={{ minHeight: addHeight ? "calc(100vh - 200px)" : "unset" }}
          className={styles.appContainer}
        >
          {user.full_name ? (
            <Routes>
              <Route path="*" element={<Navigate to="/" />} />
              {!user.active ? (
                <>
                  {user.latest_transaction_status &&
                  user.latest_transaction_status === "Pending" ? (
                    <>
                      <Route path="/" element={<HomePage />} />
                    </>
                  ) : (
                    <>
                      <Route path="/" element={<Packages />} />
                      <Route path="/payment/:id" element={<PaymentMethod />} />
                      <Route
                        path="/payment/:id/cliq"
                        element={<Pay type="CLIQ" />}
                      />
                      <Route
                        path="/payment/:id/bank"
                        element={<Pay type="Bank" />}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Route path="*" element={<Navigate to="/" />} />
                  <Route path="/" element={<HomePage />} />

                  <Route path="/account" element={<AccountHome />} />
                  <Route path="/edit-profile" element={<EditProfile />} />
                  <Route path="/change-password" element={<ResetPassword />} />

                  <Route path="/community" element={<CommunityHome />} />
                  <Route
                    path="/community/whatsapp"
                    element={<WhatsappLink />}
                  />
                  <Route
                    path="/community/members"
                    element={<CommunityMembers />}
                  />
                  <Route
                    path="/community/special-members"
                    element={<CommunityMembers special />}
                  />
                  <Route path="/community/member/:id" element={<Member />} />

                  <Route path="/payment" element={<Packages />} />
                  <Route path="/payment/:id" element={<PaymentMethod />} />
                  <Route
                    path="/payment/:id/cliq"
                    element={<Pay type="CLIQ" />}
                  />
                  <Route
                    path="/payment/:id/bank"
                    element={<Pay type="Bank" />}
                  />
                </>
              )}
            </Routes>
          ) : (
            <Routes>
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" element={<LoginPage />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route
                path="/reset-password"
                element={<ResetPassword forget />}
              />
              <Route
                path="/reset-password/new-user"
                element={<ResetPassword newUser />}
              />
              <Route path="/apply" element={<Application />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
            </Routes>
          )}
        </div>
        {user.active ? (
          <Footer />
        ) : (
          <>
            {!user.latest_transaction ||
            new Date(user.latest_transaction) <
              new Date(user.subscription_ends_at) ? (
              <></>
            ) : (
              <Footer />
            )}
          </>
        )}
      </>
    );
  else return <></>;
}

export default App;
