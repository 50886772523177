import React, { useContext, useEffect, useState } from "react";
import styles from "./Member.module.css";
import { useParams } from "react-router-dom";
import Container from "../../Components/Account/Container";
import ReactLinkify from "react-linkify";
import UserInfoItem from "../../Components/Lists/UserInfoItem";
import phone from "../../assets/Account/phone.svg";
import website from "../../assets/Account/website.svg";
import position from "../../assets/Account/position.svg";
import country from "../../assets/Account/country.svg";
import blue from "../../assets/Community/blue-tick.svg";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";

import { get } from "../../fetch";
import Context from "../../Context";

const Member = () => {
  const { id } = useParams();
  const [member, setMember] = useState(null);
  const [load, setLoad] = useState(false);
  const { setLoading } = useContext(Context);

  useEffect(() => {
    setLoading(true);
    get(`members/${id}`)
      .then((response) => {
        setMember(response);
        setLoad(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [id, setLoading]);

  const phoneCall = () => {
    window.location.href = `tel:${member.phone_number}`;
  };

  const openURL = () => {
    window.open(`https://${member.url}`, "_blank");
  };

  if (load)
    return (
      <Container>
        <img
          className={styles.img}
          src={
            member.profile_pic ? member.profile_pic.original : applicationIcon
          }
          alt={member.name}
        />
        <div className={styles.name}>
          {member.full_name}
          {member.verified ? (
            <img className={styles.verified} src={blue} alt="verified" />
          ) : (
            ""
          )}
        </div>
        <p className={styles.email}>{member.email}</p>
        <p className={styles.bio}>
          {" "}
          {member.bio &&
            member.bio.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                <ReactLinkify
                  componentDecorator={(href, text, key) => (
                    <a
                      href={href}
                      key={key}
                      target="_blank" // Open link in a new tab
                      rel="noopener noreferrer"
                      style={{ color: "your_desired_color" }} // Change the text color
                    >
                      {text}
                    </a>
                  )}
                >
                  {line}
                </ReactLinkify>
                <br />
              </React.Fragment>
            ))}
        </p>
        {member.phone_number && (
          <UserInfoItem
            item={{ text: member.phone_number, img: phone }}
            onClick={phoneCall}
          />
        )}
        {member.country && (
          <UserInfoItem item={{ text: member.country, img: country, unclickable: true }} />
        )}
        {member.url && (
          <UserInfoItem
            item={{ text: member.url, img: website }}
            onClick={openURL}
          />
        )}
        {member.industry && (
          <UserInfoItem
            item={{ text: member.industry, img: position, unclickable: true }}
          />
        )}
      </Container>
    );
  else return <></>;
};

export default Member;
