import React, { useContext, useEffect, useState } from "react";
import styles from "./Packages.module.css";
import Container from "../../Components/Account/Container";
import packages from "../../assets/Community/packagesLarge.svg";
import PackageCard from "../../Components/Community/PackageCard";
import { useNavigate } from "react-router-dom";
import Context from "../../Context";
import { get } from "../../fetch";
import PackagesPopup from "../../Components/Community/PackagesPopup";

const Packages = () => {
  const [packs, setPacks] = useState([]);
  const { logout, setLoading, user } = useContext(Context);

  const [selected, setSelected] = useState("");
  const [popup, setPopup] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    get("packages")
      .then((response) => {
        setLoading(false);
        setPacks(response);
      })
      .catch(() => {
        setLoading(false);
        // alert("Looks like there's a problem. Reload the page and if this happens again, please come back later.");
      });
  }, []);

  return (
    <Container>
      {popup.length ? <PackagesPopup bullets={popup} onClose={() => setPopup([])} /> : ""}
      <img className={styles.img} src={packages} alt="packages" />
      <h2 className={styles.title}>Community Packages</h2>
      {packs.map((pack) => (
        <PackageCard
          selected={selected}
          setSelected={setSelected}
          key={pack.id}
          id={pack.id}
          title={pack.name}
          months={pack.months}
          price={pack.price}
          star={pack.star}
          onShowDispription={() => setPopup(pack.bullets)}
        />
      ))}
      <div className={styles.btnsContainer}>
        <button className={`${styles.btn} ${styles.back}`} onClick={() => user.active ? navigate(-1) : logout()}>
          Cancel
        </button>
        <button
          className={`${styles.btn} ${styles.next}`}
          onClick={() => selected !== "" && navigate(`/payment/${selected}`)}
        >
          Next
        </button>
      </div>
    </Container>
  );
};

export default Packages;
