import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Pay.module.css";
import Container from "../../Components/Account/Container";
import cube from "../../assets/Account/cube.png";
import { useNavigate, useParams } from "react-router-dom";
import upload from "../../assets/Auth/upload.svg";
import { get, post } from "../../fetch";
import Context from "../../Context";

const Pay = ({ type }) => {
  const [accountDetails, setAccountDetails] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [load, setLoad] = useState(false);
  const { setLoading } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();

  const imgRef = useRef();

  const onImageChange = (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    setSelectedImage(e.target.files[0]);
  };

  useEffect(() => {
    setLoading(true)
    get(`packages/${id}`).then(() => {
      get("configs")
      .then((response) => {
        setLoading(false);
        setAccountDetails(response);
        setLoad(true);
      })
      .catch(() => {
        setLoading(false);
        alert("There seems to be an error, please come back later.");
        navigate(-1);
      });
    }).catch((e) => {
      setLoading(false);
      if (e.response.status === 404) {
        alert("Package not found.");
      } else {
        alert("There seems to be an error, please come back later.");
      }
      navigate(-1);
    })
  }, [setLoading]);

  const submit = () => {
    if (selectedImage === null) {
      alert("Please upload a screenshot of your transaction");
      return;
    }
    setLoading(true);
    let data = new FormData();
    data.set("image", selectedImage);
    data.set("type", type.toLowerCase());
    post(`subscribe/${id}`, data)
      .then(() => {
        alert("Thank you for your payment, we will review and confirm it soon.")
        navigate("/");
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        alert("There seems to be an error, please come back later.");
      });
  };

  if (load)
    return (
      <Container>
        <img className={styles.cube} src={cube} alt="cube" />
        <h2 className={styles.title}>{type} Payment</h2>
        <p className={styles.sectionTitle}>* Account Details</p>
        <span className={styles.note}>Please make the payment using the details below and upload a screenshot of proof of payment</span>
        <div className={styles.info}>
          <div className={styles.infoBlock}>
            <p className={styles.infoTitle}>Name:</p>
            <p className={styles.infoText}>
              {accountDetails.bank_account_owner_name}
            </p>
          </div>
          <div className={styles.infoBlock}>
            <p className={styles.infoTitle}>Bank:</p>
            <p className={styles.infoText}>{accountDetails.bank_name}</p>
          </div>
          {type === "CLIQ" && (
            <>
              <div className={styles.infoBlock}>
                <p className={styles.infoTitle}>CLIQ:</p>
                <p className={styles.infoText}>
                  {accountDetails.bank_account_cliq}
                </p>
              </div>
            </>
          )}
          {type === "Bank" && (
            <>
              <div className={styles.infoBlock}>
                <p className={styles.infoTitle}>ACC Number:</p>
                <p className={styles.infoText}>
                  {accountDetails.bank_account_number}
                </p>
              </div>
              <div className={styles.infoBlock}>
                <p className={styles.infoTitle}>IBAN:</p>
                <p className={styles.infoText}>
                  {accountDetails.bank_account_iban}
                </p>
              </div>
            </>
          )}
        </div>
        <p className={styles.sectionTitle}>* Upload Screenshot</p>
        <input
          type="file"
          accept="image/*"
          ref={imgRef}
          style={{ display: "none" }}
          onChange={onImageChange}
        />
        {selectedImage !== null ? (
          <img
            src={URL.createObjectURL(selectedImage)}
            alt=""
            height={150}
            style={{
              userSelect: "none",
              pointerEvents: "none",
              margin: "10px",
            }}
          />
        ) : (
          <></>
        )}
        <button
          className={styles.screenshot}
          onClick={() => imgRef.current.click()}
        >
          <img style={{ marginRight: 10 }} src={upload} alt="upload" />
          Upload
        </button>
        <div className={styles.btnsContainer}>
          <button
            className={`${styles.btn} ${styles.back}`}
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className={`${styles.btn} ${styles.confirm}`}
            onClick={submit}
          >
            Confirm
          </button>
        </div>
      </Container>
    );
  else return <></>;
};

export default Pay;
