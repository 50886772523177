import React, { useContext, useState } from "react";
import styles from "./LoginPage.module.css";
import Container from "../../Components/Account/Container";
import cube from "../../assets/Account/cube.png";
import emailImg from "../../assets/Auth/email.svg";
import AuthText from "../../Components/TextComponents/AuthText";
import { post } from "../../fetch";
import Context from "../../Context";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const { setLoading } = useContext(Context);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const submit = (e) => {
    e.preventDefault();
    if (email.length > 0) {
      setLoading(true);
      post("/forgot-password", { email })
        .then(() => {
          alert("A reset-password link has been sent to your email.");
          navigate("/");
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 404) alert("User not found, make sure you're using the correct email address.")
          setLoading(false);
        });
    } else setError(true);
  };

  return (
    <Container>
      <img className={styles.cube} src={cube} alt="cube" />
      <h2 className={styles.title}>Reset Password</h2>
      <form onSubmit={submit}>
        <AuthText
          type="email"
          placeholder="Email"
          value={email}
          error={error}
          onChange={(e) => {
            setError(false);
            setEmail(e.target.value);
          }}
          img={emailImg}
        />
        <button
          type="submit"
          style={{ marginTop: 30 }}
          className={styles.loginBtn}
        >
          Submit
        </button>
      </form>
    </Container>
  );
};

export default ForgetPassword;
