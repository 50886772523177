import React from 'react';
import {
  CropperDialogContainer,
  CropperDialogOverlay,
  CropperDialogHeader,
  HeaderEnd,
  CropperDialogTitle,
  CropperDialogContent,
  CropperDialogActions,
  IconButton,
  Divider
} from './Elements';
import { IoMdClose } from 'react-icons/io';

const CropperDialog = ({ child, maxWidth, isOpen, setOpen, persistant, title=null, flex=true, padding, actions=null }) => {

  const onOverlayClick = () => {
    if (persistant !== true) {
      setOpen(false);
    }
  };

  return (
    <>
      {
        isOpen ? 
        <>
          <CropperDialogOverlay onClick={onOverlayClick} />
          <CropperDialogContainer maxWidth={maxWidth}>
            <CropperDialogHeader>
              {
                title !== null ?
                <CropperDialogTitle>
                  {title}
                </CropperDialogTitle> :
                <></>
              }
              <HeaderEnd isRTL={false}>
                <IconButton onClick={() => setOpen(false)} iconSize="30px" color="rgba(0, 0, 0, 0.75)">
                  <IoMdClose />
                </IconButton>
              </HeaderEnd>
            </CropperDialogHeader>
            <Divider style={{marginBottom: '10px'}} />
            {
              flex ? <CropperDialogContent padding={padding}>
                {child}
              </CropperDialogContent> : child
            }
            {
              actions !== null ? <CropperDialogActions>
                {actions}
              </CropperDialogActions> : <></>
            }
          </CropperDialogContainer>
        </> : <></>
      }
    </>
  )
};

export default CropperDialog;
