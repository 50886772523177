import React, { useContext, useEffect, useState } from "react";
import styles from "./ResetPassword.module.css";
import Container from "../../Components/Account/Container";
import cube from "../../assets/Account/cube.png";
import password from "../../assets/Auth/password.svg";
import AuthText from "../../Components/TextComponents/AuthText";
import { useLocation, useNavigate } from "react-router-dom";
import { post, put } from "../../fetch";
import Context from "../../Context";

const ResetPassword = ({ newUser = false, forget = false }) => {
  const { setLoading } = useContext(Context);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const verToken = query.get("t");
  const navigate = useNavigate();

  useEffect(() => {
    if (newUser) {
      const query = new URLSearchParams(location.search);
      if (!query.has("t")) {
        navigate("/");
      }
    }
  }, [location, newUser, navigate]);

  const [data, setData] = useState({
    password: {
      text: "",
      error: false,
    },
    password_confirmation: {
      text: "",
      error: false,
    },
  });

  const setPassword = (password) => {
    setData({
      ...data,
      password: {
        text: password,
        error: false,
      },
    });
  };

  const setPasswordRepeat = (password) => {
    setData({
      ...data,
      password_confirmation: {
        text: password,
        error: false,
      },
    });
  };

  const submit = (e) => {
    e.preventDefault();
    if (data.password.text === "") {
      setData({
        ...data,
        password: {
          text: "",
          error: true,
        },
      });
    } else if (data.password_confirmation.text !== data.password.text) {
      setData({
        ...data,
        password_confirmation: {
          text: "",
          error: true,
        },
      });
    } else {
      setLoading(true);
      if (newUser) {
        put("password", {
          verification_token: verToken,
          password: data.password.text,
          password_confirmation: data.password_confirmation.text,
        })
          .then(() => {
            alert("Password changed successfully. You can login with your new password now.")
            setLoading(false);
            navigate("/");
          })
          .catch(() => {
            setLoading(false);
            alert("An error has occured. Please try again later");
          });
      } else if (forget) {
        post("reset-password", {
          verification_token: verToken,
          password: data.password.text,
          password_confirmation: data.password_confirmation.text,
        })
          .then(() => {
            setLoading(false);
            alert("Password changed successfully. You can login with your new password now.")
            navigate("/");
          })
          .catch((error) => {
            if (error.response.status === 404 || error.response.status === 400) alert("Your link is invalid or expired, make sure you're using the correct link");
            else alert("An error has occured. Please try again later")
            setLoading(false);
          });
      } else {
        put("change-password", {
          password: data.password.text,
          password_confirmation: data.password_confirmation.text,
        })
          .then(() => {
            alert("Password changed successfully. You can login with your new password now.")
            setLoading(false);
            navigate(-1);
          })
          .catch(() => {
            setLoading(false);
            alert("An error has occured. Please try again later");
          });
      }
    }
  };

  return (
    <Container>
      <img className={styles.cube} src={cube} alt="cube" />
      <h2 className={styles.title}>
        {newUser ? "Welcome!" : "Reset Password"}
      </h2>
      <form  onSubmit={submit}>
        <AuthText
          type="password"
          placeholder="New password"
          value={data.password.text}
          error={data.password.error}
          onChange={(e) => setPassword(e.target.value)}
          img={password}
        />
        <AuthText
          type="password"
          placeholder="Confirm new password"
          value={data.password_confirmation.text}
          error={data.password_confirmation.error}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          img={password}
        />
        <button type="submit" className={styles.loginBtn} onClick={submit}>
          Submit
        </button>
      </form>
    </Container>
  );
};

export default ResetPassword;
