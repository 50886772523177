import React, { useContext } from "react";
import styles from "./HomePage.module.css";
import community from "../assets/Home/community.svg";
import coaching from "../assets/Home/coaching.svg";
import courses from "../assets/Home/courses.svg";
import resources from "../assets/Home/resources.svg";
import account from "../assets/Home/account.svg";
import Block from "../Components/Home/Block";
import Context from "../Context";

const HomePage = () => {
  const { user } = useContext(Context);
  const blocks = [
    // {
    //   id: 1,
    //   name: "Dashboard",
    //   icon: dashboard,
    //   disabled: false,
    //   to: "/dashboard",
    // },
    {
      id: 2,
      name: "Community",
      icon: community,
      disabled: false,
      to: "/community",
    },
    { id: 3, name: "Coaching", icon: coaching, url: "www.sariawwad.com/coaching" },
    { id: 4, name: "Courses", icon: courses, disabled: true },
    {
      id: 5,
      name: "Resources",
      icon: resources,
      url: "www.thecuberesources.com/blog",
    },
    { id: 6, name: "Account", icon: account, disabled: false, to: "/account" },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <p className={styles.greetingTxt}>Hi {user.full_name.split(' ')[0]}!</p>
        <p className={styles.greetingSubtitle}>Execution is everything</p>
      </div>
      <div className={styles.grid}>
        {blocks.map((block) => (
          <Block key={block.id} block={block} />
        ))}
      </div>
    </div>
  );
};

export default HomePage;
