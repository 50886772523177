import React, { useContext, useEffect, useState } from "react";
import styles from "./AccountHome.module.css";
// import sari from "../../assets/temp/sari.svg";
import verified from "../../assets/Community/blue-tick.svg";
import Container from "../../Components/Account/Container";
import subscriptions from "../../assets/Account/subscriptions.svg";
import payment from "../../assets/Account/payment.svg";
import referralCode from "../../assets/Account/referralCode.svg";
import password from "../../assets/Account/password.svg";
import logoutIcon from "../../assets/Account/logout.svg";
import { useNavigate } from "react-router-dom";
import UserInfoItem from "../../Components/Lists/UserInfoItem";
import time from "../../assets/Account/time.svg";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";

import Context from "../../Context";

const AccountHome = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(Context);
  const [showExtendSubscription, setShowExtendSubscription] = useState(false);

  useEffect(() => {
    const subscriptionEndDate = new Date(user.subscription_ends_at);
    const currentDate = new Date();
    const timeDifference =
      subscriptionEndDate.getTime() - currentDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    if (daysDifference <= 30 && daysDifference > 0) {
      setShowExtendSubscription(true);
    } else {
      setShowExtendSubscription(false);
    }
  }, [user]);

  const items = [
    {
      id: 1,
      text:
        "Active Until: " +
        new Date(user.subscription_ends_at)
          .toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .toUpperCase(),
      img: time,
      unclickable: true,
    },
    {
      id: 2,
      text: "Referral Code: " + user.referral_code,
      img: referralCode,
    },
    { id: 4, text: "Transactions", img: payment, to: "", unclickable: true },
    { id: 5, text: "Change Password", img: password, to: "/change-password" },
    { id: 6, text: "Logout", img: logoutIcon, to: "logout" },
  ];

  return (
    <Container>
      <div className={styles.profilePicture}>
        <img
          src={user.profile_pic ? user.profile_pic.original : applicationIcon}
          style={{
            borderRadius: "50%",
            height: "100%",
            width: "100%",
            pointerEvents: "none",
            objectFit: "cover",
          }}
          alt="profile pic"
        />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          <p>{user.full_name}</p>
          {user.verified ? <img src={verified} alt="verified" /> : ""}
        </div>
        <p className={styles.email}>{user.email}</p>
      </div>
      <button
        className={styles.editProfile}
        onClick={() => navigate("/edit-profile")}
      >
        Edit Profile
      </button>
      {showExtendSubscription && <UserInfoItem onLogout={logout} item={{ text: "Extend Subscription", img: subscriptions, to: "/payment" }} />}
      {items.map((item) => (
        <UserInfoItem onLogout={logout} key={item.id} item={item} />
      ))}
    </Container>
  );
};

export default AccountHome;
