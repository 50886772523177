import React, { useEffect, useState } from "react";
import styles from "./UserInfoItem.module.css";
import { useNavigate } from "react-router-dom";

const UserInfoItem = ({ item, onLogout, onClick }) => {
  const [showCopied, setShowCopied] = useState(false);
  const navigate = useNavigate();
  const copyTextToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  useEffect(() => {
    if (showCopied) {
      setTimeout(() => setShowCopied(false), 3000);
    }
  }, [showCopied]);

  return (
    <div
      className={`${styles.listItem} ${item.to === "" && styles.disabled}`}
      key={item.id}
      style={{ pointerEvents: item.unclickable && "none" }}
      onClick={() => {
        if (onClick) {
          onClick();
        } else if (item.to === "logout") {
          onLogout();
        } else if (!item.to) {
          copyTextToClipboard(item.text);
          setShowCopied(true);
        } else {
          navigate(item.to);
        }
      }}
    >
      <>
        <img src={item.img} alt={item.text} />
        <p style={{ opacity: item.to === "" ? 0.5 : 1 }}>{item.text}</p>
      </>
      {showCopied && (
        <p style={{ fontSize: 15 }} className={styles.soon}>
          COPIED
        </p>
      )}
      {item.to === "" && (
        <p style={{ fontSize: 15 }} className={styles.soon}>
          SOON
        </p>
      )}
    </div>
  );
};

export default UserInfoItem;
