import React from "react";
import styles from "./PackageCard.module.css";
import goldenStar from "../../assets/Community/golden-star.svg";
import info from "../../assets/Community/info.svg";

const PackageCard = ({
  id,
  title,
  price,
  months,
  star,
  selected,
  setSelected,
  onShowDispription,
}) => {

  const onDescClick = (e) => {
    e.stopPropagation();

    if (onShowDispription === null) {
      return;
    }
    onShowDispription();
  };

  return (
    <div className={styles.container} onClick={() => selected === id ? setSelected("") : setSelected(id)}>
      <div className={styles.card}>
        <p className={styles.title}>{title}</p>
        <div className={styles.content}>
          <div className={styles.detailsContainer}>
            <div onClick={onDescClick} style={{ color: "red", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img className={styles.info} src={info} alt="info" />
              <span style={{ marginLeft: 3 }}>Package info</span>
            </div>
            <p className={styles.details} style={{ fontSize: 14 }}>{price} JD</p>
            <p className={styles.details}>{months} Months</p>
          </div>
          {star ? <img className={styles.star} src={goldenStar} alt="star" /> : ""}
        </div>
      </div>
      <button className={`${styles.check} ${selected === id && styles.selected}`} />
    </div>
  );
};

export default PackageCard;
