import React, { useState, useEffect, useContext } from "react";
import styles from "./PaymentMethod.module.css";
import Container from "../../Components/Account/Container";
import cube from "../../assets/Account/cube.png";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../fetch"
import Context from "../../Context";

const PaymentMethod = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const { id } = useParams();
  const { setLoading } = useContext(Context);
  const payments = [
    { id: 1, type: "cliq", enabled: true },
    { id: 2, type: "bank", enabled: true },
    { id: 3, type: "visa", enabled: false },
  ];

  const select = (type) => {
    setSelected(type);
  };
  
  useEffect(() => {
    setLoading(true)
    get(`packages/${id}`).then(() => setLoading(false)).catch((e) => {
      setLoading(false);
      if (e.response.status === 404) {
        alert("Package not found.");
        navigate(-1);
      } else {
        alert("There seems to be an error, please come back later.");
        navigate(-1);
      }
    })
  }, []);

  return (
    <Container back={false}>
      <img className={styles.cube} src={cube} alt="cube" />
      <h2 className={styles.title}>Payment Method</h2>
      {payments.map((payment) => (
        <div
          className={styles.cardContainer}
          onClick={() => payment.enabled && select(payment.type)}
        >
          <div className={`${styles.card} ${styles[payment.type]}`} />
          <button
            className={`${payment.enabled ? styles.check : styles.disabled} ${selected === payment.type && styles.selected
              }`}
          />
        </div>
      ))}
      <div className={styles.btnsContainer}>
        <button className={`${styles.btn} ${styles.back}`} onClick={() => navigate(-1)}>
          Back
        </button>
        <button className={`${styles.btn} ${styles.next}`} onClick={() => selected !== "" && navigate(`/payment/${id}/${selected}`)}>
          Next
        </button>
      </div>
    </Container>
  );
};

export default PaymentMethod;
