import React, { useEffect, useState } from "react";
import Context from "./Context";
import { get, post, setAuthorizationToken } from "./fetch";
import { useNavigate } from "react-router-dom";

const Provider = ({ children }) => {
  const [token, setToken] = useState("");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token === "" && localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"));
    }
    setAuthorizationToken(token);
  }, [token]);

  useEffect(() => {
    if (token !== "") {
      get("user")
        .then((response) => {
          if (response.user_type === "user") setUser(response);
          else {
            logout();
            alert("Please login using the admin portal");
          }
        })
        .catch(() => {
          logout();
        });
    }
  }, [token]);

  const auth = (data) => {
    setLoading(true);
    post("login", data)
      .then((response) => {
        setLoading(false)
        setToken(response.token);
        localStorage.setItem("token", response.token);
      })
      .catch((error) => {
        setLoading(false)
        alert(error.response.data.message);
      });
  };

  const navigate = useNavigate();
  const logout = () => {
    setLoading(true);
    post("logout")
      .then(() => {
        setLoading(false)
        setToken("");
        setUser({});
        localStorage.clear();
        navigate("/");
      })
      .catch(() => {
        setLoading(false)
        setToken("");
        setUser({});
        localStorage.clear();
      });
  };

  return (
    <Context.Provider value={{ user, auth, logout, loading, setLoading }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
