import React from "react";
import styles from "./MembersCard.module.css";
import { useNavigate } from "react-router-dom";
import blue from "../../assets/Community/blue-tick.svg";
import applicationIcon from "../../assets/Dashboard/application-icon.svg";

const MembersCard = ({ member }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/community/member/${member.id}`)}
    >
      <div className={styles.content}>
        <img
          className={styles.img}
          src={member.profile_pic ? member.profile_pic.original : applicationIcon}
          alt={member.full_name}
        />
        <div className={styles.txtContainer}>
          <p className={styles.name}>{member.full_name}</p>
          <p className={styles.industry}>{member.industry}</p>
        </div>
      </div>
      {member.verified ? (
        <img className={styles.checkmark} src={blue} alt="blue tick" />
      ) : (
        ""
      )}
    </div>
  );
};

export default MembersCard;
