import React from "react";
import styles from "./TextIconOutside.module.css";

import ReactPhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import Select from "react-select";

const TextIconOutside = ({
  type,
  value,
  icon,
  onChange,
  placeholder,
  select = false,
  options,
}) => {

  const searchStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      padding: 0,
      backgroundColor: "none",
      height: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#437689" : null,
      color: state.isSelected ? "white" : null,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: value && value.length > 0 ? "black" : "grey",
    }),
  };

  return (
    <div className={styles.container}>
      <img className={styles.icon} src={icon} alt="icon" />
      {!select && (
        <>
          {type === "phone" ? (
            <ReactPhoneInput
              placeholder="+962 7 9999 9999"
              value={value}
              className={styles.input}
              onChange={(value) => onChange(value)}
              defaultCountry="JO"
              style={{ paddingLeft: 10, paddingRight: 10 }}
            />
          ) : (
            <input
              placeholder={placeholder}
              className={styles.input}
              type={type}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            />
          )}
        </>
      )}
      {select && (
        <Select
        className={styles.input}
        value={{ label: value || placeholder, value: value }}
        onChange={(selectedOption) => onChange(selectedOption.value)}
        options={options.map((option) => ({ label: option, value: option }))}
        styles={searchStyles}
        isSearchable
      />
      )}
    </div>
  );
};

export default TextIconOutside;
