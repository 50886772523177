import React, { useEffect, useState } from "react";
import styles from "./PackagesPopup.module.css";

const PackagesPopup = ({ onClose, bullets = [] }) => {
  const [isVisible, setIsVisible] = useState(false);

  const show = () => {
    setIsVisible(true);
  };

  const hide = () => {
    setIsVisible(false);
    setTimeout(() => onClose(), 300)
  };

  useEffect(() => {
    const box = document.getElementById("popupBox");

    const handleTransitionEnd = () => {
      // Remove the event listener to avoid multiple calls
      box.removeEventListener("transitionend", handleTransitionEnd);
      onClose();
    };

    show();
  }, [onClose]);

  return (
    <div className={styles.container} onClick={hide}>
      <div
        className={`${styles.box} ${isVisible ? styles.visible : ""}`}
        id="popupBox"
      >
        {bullets.map((bullet) => (
          <div key={bullet.id} className={styles.bullet}>
            <p
              className={styles.bulletText}
              style={{
                margin: 0,
                marginTop: 2,
                marginRight: 5,
                width: "unset",
                fontSize: 20,
              }}
            >
              ‣
            </p>
            <p className={styles.bulletText}>{bullet.text}</p>
          </div>
        ))}
        <button onClick={hide} className={styles.close}>
          Close
        </button>
      </div>
    </div>
  );
};

export default PackagesPopup;
