import React from "react";
import styles from "./CommunityCard.module.css";
import { useNavigate } from "react-router-dom";

const CommunityCard = ({ icon, name, color, to }) => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.container} ${to === "" && styles.inactive}`} onClick={() => navigate(to)}>
      <img className={styles.icon} src={icon} alt="icon" />
      <p style={{ color }} className={styles.name}>
        {name}
      </p>
      {to === "" && <p className={styles.soon}>SOON</p>}
    </div>
  );
};

export default CommunityCard;
