import React, { useEffect, useState, useContext } from "react";
import styles from "./Header.module.css";
import HeaderLogo from "../assets/Header/HeaderLogo.svg";
import Context from "../Context";
import { useNavigate } from "react-router-dom";
import arrow from "../assets/Account/arrow.svg";

const Header = () => {
  const { user, logout } = useContext(Context);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({});

  useEffect(() => {
    const subscriptionEndDate = new Date(user.subscription_ends_at);
    const currentDate = new Date();
    const timeDifference =
      subscriptionEndDate.getTime() - currentDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    if (daysDifference <= 30 && daysDifference > 0) {
      setAlert({
        title: `Warning`,
        body: `Your subscription ends in ${parseInt(daysDifference)} days. You can extend your subscription in account settings.`,
      });
    }
  }, [user]);

  useEffect(() => {
    if (user.full_name && !user.active) {
      setAlert({
        title: `Hang tight! ⌛`,
        body: `Payment pending confirmation.`,
      });
    }
  }, [user]);
  useEffect(() => {
    if (user.full_name && user.active && (!user.bio || !user.profile_pic)) {
      setAlert({
        title: `Hello ${user.full_name.split(' ')[0]}!`,
        body: `Complete your profile for a better experience. Edit your profile in account settings.`,
      });
    }
  }, [user]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img
          src={HeaderLogo}
          alt="logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />
        <div className={styles.logout} onClick={logout} />
      </div>
      {alert.title && (
        <div className={styles.card}>
          <p className={styles.alertTitle}>{alert.title}</p>
          <p className={styles.alertBody}>{alert.body}</p>
          <img
            className={styles.arrow}
            src={arrow}
            alt="arrow"
            onClick={() => setAlert({})}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
