import React from "react";
import styles from "./Footer.module.css";
import HeaderLogo from "../assets/Header/HeaderLogo.svg";

const Footer = () => {
  return (
    <div className={styles.container}>
      <img src={HeaderLogo} alt="logo" className={styles.logo} />
    </div>
  );
};

export default Footer;
